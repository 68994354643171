<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
  },
  mounted() {
  },
  data() {
    return {};
  },
  methods: {
    
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Dashboard</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Welcome to Room Diplomacy Dashboard</li>
          </ol>
        </div>
      </div>
    </div>
  </Layout>
</template>